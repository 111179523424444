<template>
    <section>
        <label class="caption">{{ label }}</label>
        <v-textarea 
            outlined 
            dense 
            required
            :readonly="readonly"
            :disabled="disabled"
            :rules="[ v => !!v || 'This is required']"
            :value="value"
            :error-messages="error"
            class="general-custom-field mb-1"
            @input="$emit('update:value', $event)"
            hide-details="auto"/>
    </section>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    props: {
        label: {
            type: String,
            default: ""
        },
        value: {
            type: String,
            default: ""
        },
        error: {
            type: String,
            default: ""
        },
        readonly: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
})
</script>